.portal-option-card {
  border: 2px solid #e7e7e7;
  border-radius: 8px;
  margin: 12px 0px;
  padding: 24px;
  min-width: 400px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  img {
    margin: 12px 0px;
  }
  cursor: pointer;
  transition: all 300ms;
}

.portal-option-card:hover {
  background-color: rgba(231, 231, 231, 0.5);
}

.nyc-card {
  margin-top: 128px;
}

.household-container {
  width: 100%;
  margin: 48px 0px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.household-element {
  display: flex;
  flex-flow: row;
  margin: 18px 0px;
  width: 410px;
  justify-content: space-between;
  align-items: center;
  .household-text {
    width: 320px;
  }

  img {
    margin: 0px 12px;
    cursor: pointer;
  }
  h6 {
    margin: 0px;
  }

  div {
    display: flex;
    flex-flow: row;
    align-items: center;
  }
}

.household-cards-container {
  width: 100%;
  margin: 12px 0px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-evenly;
}

.household-card-component {
  background-color: #465b7a;
  padding: 12px;
  margin: 12px 6px;
  border-radius: 8px;
  width: 350px;
  height: 170px;
  color: white;

  display: flex;
  flex-flow: column;
  align-items: center;

  .household-card-icon-container {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    width: 100%;
  }

  h1 {
    position: relative;
    top: -10px;
    font-size: 18px;
    margin-bottom: 0px;
  }
  h2 {
    margin-bottom: 2px;
    font-size: 24px;
  }
  h3 {
    font-size: 12px;
    margin-bottom: 6px;
    font-weight: 300;
  }

  h4 {
    position: relative;
    bottom: -18px;
    font-size: 16px;
    margin-top: 12px;
  }
}

.cancel-funding-button-container {
  display: flex;
  flex-flow: row;
  width: 100%;
  span {
    color: #000000;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
  }
}

.success-message-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  height: 750px;
  h3,
  h4 {
    text-align: center;
  }
  h4 {
    margin: 12px 0px 48px 0px;
  }
  img {
    margin: 24px 0px;
  }
}

// Wizard Steps

.wizard-steps-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0px;
  width: 100%;
  max-width: 500px;
  p {
    margin: 0px;
  }
  div {
    display: flex;
    flex-flow: row;
    align-items: center;
    img {
      margin: 0px 6px;
      height: 12px;
    }
  }
}

.cancel-funding-button {
  margin-top: 12px;
  text-decoration: underline;
  width: fit-content;
  cursor: pointer;
}

.supplementalfunding-component {
  display: flex;
  align-items: center;
  img {
    height: 36px;
    transition: all 300ms;
    cursor: pointer;
  }
  img:hover {
    opacity: 0.5;
  }

  h6 {
    margin: 0px 12px;
  }
}

.confirmation-screen {
  width: 1200px;
}

@media only screen and (max-width: 1200px) {
  .confirmation-screen {
    width: 600px;
  }
}

@media only screen and (max-width: 600px) {
  .confirmation-screen {
    width: 400px;
  }
}

@media only screen and (max-width: 400px) {
  .confirmation-screen {
    width: 320px;
  }
}
